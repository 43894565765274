<template>
    <!-- 侧边栏菜单 -->
    <el-menu :router="router" :collapse-transition='false' @select="handleOpen"
     :collapse="iscollapse"  :default-active="$route.path">
      <template v-for="item in menuList" >
        <!-- 有分组 -->
        <el-submenu v-if="item.child" :index="item.index"
         v-bind:key="item.title">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{item.title}}</span>
          </template>
          <template
          v-for="itemGroup in item.child">
          <!-- group -->
            <el-menu-item-group v-if="itemGroup.type=='group'"  v-bind:key="itemGroup.groupTitle" >
            <span slot="title" v-if="itemGroup.groupTitle&&itemGroup.groupTitle!=''">{{itemGroup.groupTitle}}</span>
            <el-menu-item v-for="child in itemGroup.child" v-bind:key="child.title"
             :index="child.index">{{child.title}}</el-menu-item>
          </el-menu-item-group>
          <!-- group -->
          <!-- option -->
            <el-submenu v-bind:key="itemGroup.groupTitle" :index="itemGroup.index" v-if="itemGroup.type=='option'">
                <template slot="title">{{itemGroup.groupTitle}}</template>
                <el-menu-item  v-for="child in itemGroup.child" v-bind:key="child.title"
             :index="child.index">{{child.title}}</el-menu-item>
            </el-submenu>
          <!-- option -->
          </template>

        </el-submenu>
          <!-- 无分组 -->
        <el-menu-item  v-else
         v-bind:key="item.title" :index="item.index">
          <i :class="item.icon"></i>
          <span slot="title">{{item.title}}</span>
        </el-menu-item>
      </template>
    </el-menu>
</template>
<script>
export default {
    name:"Tabbar",
    props: {
      router: Boolean,
      iscollapse: Boolean,
      menuList:{
        type:Array,
        value:[]
      }
    },
    data(){
      return{
        defaultAct:'',
        openeds:['1']
      }
    },
    created(){
    },
    methods: {
        handleOpen(key,keyPath) {//keyPath
            //console.log(key, keyPath);
            this.$emit("navto",key,keyPath)
        }
    }
}
</script>