<template>
          <el-container class="el-container" 
            :style="'height:'+winHeight+'px'">
                    <el-aside :width="asideWidth+'px'" v-if="nid!=0">
                    <Logo v-if="!iscollapse"/>
                    <Tabbar @navto="navto" :iscollapse="iscollapse" :menuList="menuList"/>
                    </el-aside>
                    <el-container>
                    <el-header>
                     <!-- header -->
                     <div class="header-left">
                         <h2>{{doc.length>=1&&nid!=0?doc[0].title:article.title}}</h2>
                     </div>
                     <div class="header-right">
                        <el-autocomplete
                            v-if="nid!=0"
                            v-model="keys"
                            :fetch-suggestions="getDocList"
                            placeholder="请输入关键字"
                            @select="search"
                        ></el-autocomplete>
                        <div class="r-item">
                            <i class="el-icon-share" title="分享" ></i>
                        </div>
                        <div class="r-item">
                            <i class="el-icon-download" title="导出" ></i>
                        </div>
                        <div class="r-item">
                            <el-popover
                                placement="top-start"
                                title="其他信息"
                                width="200"
                                trigger="hover"
                                :content="article.author+'创作于'+article.time">
                                <i class="el-icon-more" slot="reference"></i>
                            </el-popover>
                        </div>
                     </div>
                    </el-header>
                    <el-main>
                        <keep-alive>
                            <div class="content" v-html="article.content"></div>
                        </keep-alive>
                    </el-main>
                    </el-container>
            </el-container>
</template>
<script>
import Tabbar from '@/components/common/MenuList'
import Logo from '@/components/common/Logo'
export default {
    components: {
        Tabbar,
        Logo
    },
    data(){
        return{
            keys:"",//关键字
            doc:[],//该文档所属专栏
            strNull:"nexo-null-",
            baseUrl:"/Document/Document/",
            menuList:[],
            asideWidth:200,
            winHeight:window.innerHeight,
            showMenu:true,
            iscollapse:false,
            id:null,
            nid:null,//专栏id
            article:{
                title:"",
                content:""
            },
            articleList:[],//该专栏下的文章列表
            docList:[],//专栏列表
            sections:[],//section节列表
            chapters:[] //章列表
        }
    },
    mounted(){
        this.id = this.$route.params.id 
        this.nid = this.$route.params.nid
        //console.log("mouted",this.id,this.nid)
        //获取文档
        this.getDocument()
    },methods:{
        search(e){
            console.log(e)
        },
        getDocList(String,cb){
            if(String==""){
                return
            }
            let params = {
                method:"getDocList",
                page:1,
                keys:String,
                pageSize:10
            }
            this.nid!=0?params.nid = this.nid :""
            this.loading = true 
            //console.log(params)
            this.$ajax.post(this.API.api,params).then(res=>{
                //res
                console.log(res)
                this.loading = false
                let list=[]
                res.data.list.forEach(function(item){
                    list.push({
                        id:item.id,
                        nid:item.nid,
                        cid:item.cid,
                        value:item.title
                    })
                })
                console.log(list)
                cb(list) 
            }).catch(err=>{
                //err
                console.log(err)
                this.loading = false
                this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
            })
        },
        navto(key,keyPath){
            console.log(key,keyPath) 
            if(key==""||!key||key.indexOf(this.strNull)!=-1){
                return
            }
            this.$router.push(key)
            this.id = this.$route.params.id 
            this.nid = this.$route.params.nid
            //console.log(this.id,this.nid,key)
            this.getDocument()
        },
        //按照数据重组
        resolveList(){
            let menuList = this.menuList 
            ,articleList = this.articleList,that = this
            menuList.forEach(function(item){
                if(item.child){
                    //有子节内容则继续循环获取节内容
                    item.child.forEach(function(ciIt){
                        articleList.forEach(function(arIt){
                            //console.log(ciIt.id,arIt.sid)
                            if(ciIt.id==arIt.sid){
                                !ciIt.child?ciIt.child=[]:''
                                ciIt.child.push({
                                    title:arIt.title,
                                    id:arIt.id,
                                    index:that.baseUrl+that.nid+"/"+arIt.id
                                })
                            }
                        })
                    })
                }else{
                    //无子节内容
                    articleList.forEach(function(arIt){
                        if(arIt.cid==item.id){
                            //console.log(arIt.title,item.title)
                            item.index = that.baseUrl+that.nid+"/"+arIt.id
                        }
                    })
                }
            })
            console.log(menuList)
        },
        //获取专栏名称列表
        getNameList(){
            let params = {
                method:"getDocNameList",
                nid:this.nid,
            }
            let that = this
            //console.log(params)
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                console.log(res)
                this.doc = res.data.doc_name
                let menuList = []
                res.data.doc_chapter.forEach(function(item,index){
                    menuList.push({
                        title:item.title,
                        id:item.id,
                        index:that.strNull+index
                    })
                    res.data.doc_section.forEach(function(it,id){
                        //console.log(item.id,it.cid)
                        if(it.cid==item.id){
                            !menuList[index].child?menuList[index].child=[]:""
                            menuList[index].child.push({
                                groupTitle:it.title,
                                id:it.id,
                                index:that.strNull+id,
                                type:"group",
                                child:[]
                            })
                        }
                    })
                })
                //console.log(menuList)
                this.menuList = menuList
                //将列表内容添加到菜单中
                this.resolveList()
            }).catch(err=>{
                //err
                console.log(err)
            })
        },
        getDocument(){
            let params = {
                method:'getDocument',
                id:this.id,
                nid:this.nid,
                Loading:true
            }
           // console.log(params,this.API.api)
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                this.article = res.data.data[0]
                document.title = this.article.title
                if(res.data.list){
                    this.articleList = res.data.list
                }
                this.getNameList()
                //console.log(this.article)
            }).catch(err=>{
                //err
                console.log(err)
                this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
            })
        }
    }
}
</script>
<style lang="less">
.el-container{
    .el-header{
        display: flex;
        .header-left{
            flex:1;
            h1{
                font-weight: 500;
                color: teal;
            }
        }
        .header-right{
            flex: 2;
            display: flex;
            justify-content: flex-end;
            .r-item{
                margin:0 20px;

                i{
                    font-size:18px;
                    cursor: pointer;
                    color: #666;
                }
                i:hover{
                    color:#333;
                }
            }
        }
    }
    
    .el-menu{
        border-right:0px
    }
    .el-aside{
        border-right: 1px #DBDBDB solid;
    }
   .content{
       padding: 10px 15px;

       table{
           border-collapse: collapse;

           th,td{
               padding: 10px;
               border:1px #ddd solid
           }
           td{
               color:#666
           }
       }

       pre{
           background: #FBFBFB;
           padding: 10px;
           border:1px solid #dbdbdb;
           border-radius: 10px;
           color: #666;
       }
   }
   :matches(article, aside, nav, section) h1 {
    font-size: 2.0em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    }
}


</style>